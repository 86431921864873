<template>
  <div class="total">
    <div class="configure">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane disabled label="教材" name="first"> </el-tab-pane>
        <el-tab-pane label="库存" name="first">
          <div class="work-title bg-white flex justify-content-center">
            <el-button
              round
              :class="{ 'color-box': jobList }"
              @click="job_List(true)"
              >库存查询</el-button
            >
            <el-button
              :class="{ 'color-box': !jobList }"
              round
              @click="job_List()"
              >库存变动记录</el-button
            >
          </div>
          <div v-if="jobList" class="work-title bg-white">
            <div class="margin-top-sm">
              <el-button type="primary">导出</el-button>
            </div>
            <div>
              <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForm"
              >
                <el-form-item label="课程类别">
                  <div class="width-100 flex flex-wrap-wrap">
                    <div style="width: 95%">
                      <el-checkbox-group v-model="screen.courseCategory">
                        <el-checkbox
                          v-for="(item, index) in 4"
                          :key="index"
                          :label="'通用教材/杂费' + index"
                          name="type"
                        ></el-checkbox>
                        <div v-if="openScreen">
                          <el-checkbox
                            v-for="(item, index) in 10"
                            :key="index"
                            :label="'高一生物精品班' + index"
                            name="type"
                          ></el-checkbox>
                        </div>
                      </el-checkbox-group>
                    </div>
                    <div style="width: 5%">
                      <span
                        v-if="!openScreen"
                        @click="openScreen = !openScreen"
                        class="text-blue cursor"
                        slot="reference"
                        >展开 <i class="el-icon-caret-bottom text-blue"
                      /></span>
                      <span
                        v-if="openScreen"
                        @click="openScreen = !openScreen"
                        class="text-blue cursor"
                        slot="reference"
                        >收起 <i class="el-icon-caret-top text-blue"
                      /></span>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="库存不足">
                  <el-checkbox-group v-model="screen.stock">
                    <el-checkbox label="是" name="type"></el-checkbox>
                    <el-checkbox label="否" name="type"></el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="状态">
                  <el-checkbox-group v-model="screen.state">
                    <el-checkbox label="正常" name="type"></el-checkbox>
                    <el-checkbox label="下架" name="type"></el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <div class="flex justify-content-center" style="width: 100%">
                  <el-button type="primary" @click="show = true" size="mini"
                    >查询</el-button
                  >
                </div>
              </el-form>
            </div>
            <div style="margin-top: 10px">
              <as-list :initdataList="datalist">
                <el-table-column
                  align="center"
                  prop="banji"
                  label="教材名称"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="name"
                  label="仓库"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="name1"
                  label="驿都城校区"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="text3"
                  label="总计"
                ></el-table-column>
              </as-list>
            </div>
          </div>
          <div v-if="!jobList" class="work-title bg-white">
            <div class="margin-top-sm width-100 flex flex-wrap-wrap">
              <div style="width: 80%">
                <el-button>入库</el-button>
                <el-button>出库</el-button>
                <el-button>转库</el-button>
                <el-button>导出</el-button>
              </div>
              <div style="width: 20%">
                <el-input
                  clearable
                  placeholder="请输入教材名称"
                  v-model="stuInput"
                  class="input-with-select"
                  size="small"
                >
                  <el-button slot="append" icon="el-icon-search" />
                </el-input>
              </div>
            </div>
            <div class="margin-top">
              <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForm"
              >
                <el-form-item label="出库日期">
                  <div class="block">
                    <el-date-picker
                      v-model="deliveryDate"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                    >
                    </el-date-picker>
                  </div>
                </el-form-item>
                <el-form-item label="所属库房">
                  <el-checkbox-group v-model="Warehouse">
                    <el-checkbox label="仓库" name="type"></el-checkbox>
                    <el-checkbox label="驿都城校区" name="type"></el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="业务类型">
                  <el-checkbox-group v-model="businessType">
                    <el-checkbox label="入库" name="type"></el-checkbox>
                    <el-checkbox label="出库" name="type"></el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="教材名称"> </el-form-item>
                <div class="flex justify-content-center" style="width: 100%">
                  <el-button type="primary" @click="show = true" size="mini"
                    >查询</el-button
                  >
                </div>
              </el-form>
            </div>
            <div style="margin-top: 10px">
              <div class="text-center margin">
                当前结果：共14条记录，入库总计14&nbsp;&nbsp;出库总计：0&nbsp;&nbsp;变动总计14
              </div>
              <as-list :initdataList="datalist">
                <el-table-column
                  align="center"
                  prop="banji"
                  label="所属库房"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="name"
                  label="教材名称"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="name1"
                  label="业务类型"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="text3"
                  label="数量"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="text3"
                  label="单价"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="text3"
                  label="出库日期"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="text3"
                  label="经办人"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="text3"
                  label="学员"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="text3"
                  label="订单号"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="text3"
                  label="备注"
                ></el-table-column>
                <el-table-column width="70" label="操作">
                  <el-popover placement="top-start" trigger="hover">
                    <div style="text-align: right; margin: 0">
                      <el-button size="mini" @click="visible = false"
                        >确定</el-button
                      >
                      <el-button size="mini" @click="visible = false"
                        >编辑</el-button
                      >
                    </div>
                    <span class="text-blue cursor" slot="reference"
                      >操作 <i class="el-icon-caret-bottom text-blue"
                    /></span>
                  </el-popover>
                </el-table-column>
              </as-list>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="教材设置" name="first2">
          <div class="work-title bg-white">
            <div class="margin-top-sm width-100 flex flex-wrap-wrap">
              <div style="width: 80%">
                <el-button @click="Popup(1)">新建教材</el-button>
                <el-button @click="Popup(2)">批量导入</el-button>
              </div>
              <div style="width: 20%">
                <el-input
                  clearable
                  placeholder="请输入教材名称"
                  v-model="stuInput"
                  class="input-with-select"
                  size="small"
                >
                  <el-button slot="append" icon="el-icon-search" />
                </el-input>
              </div>
            </div>
            <div class="margin-top">
              <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForm"
              >
                <el-form-item label="适用课程类别">
                  <el-checkbox-group v-model="Warehouse">
                    <el-checkbox
                      label="通用教材/杂费"
                      name="type"
                    ></el-checkbox>
                    <el-checkbox
                      label="高一生物精品班"
                      name="type"
                    ></el-checkbox>
                    <el-checkbox label="理科10人班" name="type"></el-checkbox>
                    <el-checkbox label="初中全日制" name="type"></el-checkbox>
                    <el-checkbox label="高中全日制" name="type"></el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="状态">
                  <el-checkbox-group v-model="businessType">
                    <el-checkbox label="正常" name="type"></el-checkbox>
                    <el-checkbox label="下架" name="type"></el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <div class="flex justify-content-center" style="width: 100%">
                  <el-button type="primary" @click="show = true" size="mini"
                    >查询</el-button
                  >
                </div>
              </el-form>
            </div>
            <div style="margin-top: 10px">
              <div class="text-center margin">
                当前结果：共14条记录，入库总计14&nbsp;&nbsp;出库总计：0&nbsp;&nbsp;变动总计14
              </div>
              <as-list :initdataList="datalist">
                <el-table-column
                  align="center"
                  prop="banji"
                  label="教材名称"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="name"
                  label="进价"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="name1"
                  label="售价"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="text3"
                  label="积分兑换"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="text3"
                  label="课程类别"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="text3"
                  label="状态"
                ></el-table-column>

                <el-table-column width="70" label="操作">
                  <el-popover placement="top-start" trigger="hover">
                    <div style="text-align: right; margin: 0">
                      <el-button size="mini" @click="visible = false"
                        >确定</el-button
                      >
                      <el-button size="mini" @click="visible = false"
                        >编辑</el-button
                      >
                    </div>
                    <span class="text-blue cursor" slot="reference"
                      >操作 <i class="el-icon-caret-bottom text-blue"
                    /></span>
                  </el-popover>
                </el-table-column>
              </as-list>
            </div>
          </div>
        </el-tab-pane>

        <el-tab-pane label="杂费设置" name="first3">
          <div class="work-title bg-white">
            <div class="margin-top-sm width-100 flex flex-wrap-wrap">
              <div style="width: 80%">
                <el-button @click="Popup(3)">新建杂费</el-button>
              </div>
              <div style="width: 20%">
                <el-input
                  clearable
                  placeholder="请输入教材名称"
                  v-model="stuInput"
                  class="input-with-select"
                  size="small"
                >
                  <el-button slot="append" icon="el-icon-search" />
                </el-input>
              </div>
            </div>
            <div style="margin-top: 10px">
              <div class="text-center margin">当前结果：共14条记录</div>
              <as-list :initdataList="datalist">
                <el-table-column
                  align="center"
                  prop="banji"
                  label="教材名称"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="name"
                  label="进价"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="name1"
                  label="售价"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="text3"
                  label="积分兑换"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="text3"
                  label="课程类别"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="text3"
                  label="状态"
                ></el-table-column>

                <el-table-column width="70" label="操作">
                  <el-popover placement="top-start" trigger="hover">
                    <div style="text-align: right; margin: 0">
                      <el-button size="mini" @click="visible = false"
                        >确定</el-button
                      >
                      <el-button size="mini" @click="visible = false"
                        >编辑</el-button
                      >
                    </div>
                    <span class="text-blue cursor" slot="reference"
                      >操作 <i class="el-icon-caret-bottom text-blue"
                    /></span>
                  </el-popover>
                </el-table-column>
              </as-list>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <newTextbooks
      :Visible="editVisible && editType == 1"
      @closepop="closeEditPop"
    ></newTextbooks>
    <batchImport
      :Visible="editVisible && editType == 2"
      @closepop="closeEditPop"
    ></batchImport>
    <addIncidental
      :Visible="editVisible && editType == 3"
      @closepop="closeEditPop"
    ></addIncidental>
  </div>
</template>
<script>
import AsList from "@/components/as-list";

import newTextbooks from "../compinents/newTextbooks.vue";
import batchImport from "../compinents/batchImport.vue";
import addIncidental from "../compinents/addIncidental.vue";
export default {
  components: {
    AsList,
    newTextbooks,
    batchImport,
    addIncidental,
  },
  data() {
    return {
      stuInput: "",
      deliveryDate: "", //出库日期
      businessType: [], //业务类型
      Warehouse: [], //所属库房
      openScreen: false,
      screen: {
        courseCategory: [], //课程类别
        stock: [], //库存
        state: [], //状态
      },
      jobList: true,
      datalist: [
        {
          banji: "寒假愉快",
          name: "万豪_艺术区高二",
          name1: "周杰",
          text3: "进行中",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          banji: "寒假愉快",
          name: "万豪_艺术区高二",
          name1: "周杰",
          text3: "进行中",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          banji: "寒假愉快",
          name: "万豪_艺术区高二",
          name1: "周杰",
          text3: "进行中",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          banji: "寒假愉快",
          name: "万豪_艺术区高二",
          name1: "周杰",
          text3: "进行中",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          banji: "寒假愉快",
          name: "万豪_艺术区高二",
          name1: "周杰",
          text3: "进行中",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          banji: "寒假愉快",
          name: "万豪_艺术区高二",
          name1: "周杰",
          text3: "进行中",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          banji: "寒假愉快",
          name: "万豪_艺术区高二",
          name1: "周杰",
          text3: "进行中",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          banji: "寒假愉快",
          name: "万豪_艺术区高二",
          name1: "周杰",
          text3: "进行中",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          banji: "寒假愉快",
          name: "万豪_艺术区高二",
          name1: "周杰",
          text3: "进行中",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          banji: "寒假愉快",
          name: "万豪_艺术区高二",
          name1: "周杰",
          text3: "进行中",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          banji: "寒假愉快",
          name: "万豪_艺术区高二",
          name1: "周杰",
          text3: "进行中",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          banji: "寒假愉快",
          name: "万豪_艺术区高二",
          name1: "周杰",
          text3: "进行中",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          banji: "寒假愉快",
          name: "万豪_艺术区高二",
          name1: "周杰",
          text3: "进行中",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          banji: "寒假愉快",
          name: "万豪_艺术区高二",
          name1: "周杰",
          text3: "进行中",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
      ],

      activeName: "first",
    };
  },
  methods: {
    job_List(t) {
      if (t) {
        this.jobList = true;
      } else {
        this.jobList = false;
      }
    },
    Popup(type) {
      this.editVisible = true;
      this.editType = type;
    },
    JumpUrl(url) {
      this.$router.push(url);
    },
    handleClick(row) { 
    },
    editHandle(type) {
      this.editVisible = true;
      this.editType = type;
    },
  },
};
</script>

<style lang="scss">
>>> .el-date-editor .el-range-separator {
  padding: 0 5px;
  line-height: 32px;
  width: 9%;
  color: #303133;
}
.color-box {
  color: #0081ff;
  border: 1px solid #8cc5ff;
  background-color: #e8f5ff;
}

.total {
  width: 100%;
}
.TopLabel {
  widows: 200px;
}
.TopLabel_1 {
  widows: 300px;
}

.configure {
  width: 100%;
  background-color: #fff;

  padding: 30px 10px 10px 10px;
}
</style>