<template>
  <el-dialog
    title="新建教材"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="close"
    width="40%"
  >
    <div class="myClient-top-10 padding">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="杂费名称" prop="desc">
          <el-input
            type="textarea"
            placeholder="请填写实物收费项目（如于续费，体验费），最多30字"
            maxlength="30"
            v-model="ruleForm.desc"
          ></el-input>
        </el-form-item>
        <el-form-item label="课程类别" prop="largeClass">
          <el-select v-model="ruleForm.largeClass" placeholder="请选择课程类别">
            <el-option label="通用教材/杂费" value=""></el-option>
            <el-option label="课程类别二" value="beijing"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="售价" prop="money">
          <el-input
            v-model="ruleForm.money2"
            clearable
            placeholder="请输入金额"
          />
        </el-form-item>

        <el-form-item label="状态">
          <el-radio-group v-model="ruleForm.type">
            <el-radio label="正常"></el-radio>
            <el-radio label="下架"></el-radio>
          </el-radio-group>
          <p>
            <el-checkbox v-model="integral">积分兑换</el-checkbox>
            <el-input
              style="width: 100px; margin-left: 20px"
              v-model="input"
              :disabled="!integral"
            >
            </el-input>
          </p>
        </el-form-item>
      </el-form>
    </div>
    <div class="flex justify-content-center">
      <el-button class="myClient-left-30" @click="close">取消</el-button>
      <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    Visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const validateMoney = (rule, value, callback) => { 
      if (value > this.precontractAmount) {
        callback(new Error("计划付款金额需小于合同金额"));
      } else {
        callback();
      }
    };
    return {
      integral: false, //积分
      dialogVisible: false,
      ruleForm: {
        shoolName: [], //经办校区
        largeClass: "", //大类
        smallClass: "", //小类
        account: "", //账户
        date1: new Date(),
        // .toLocaleDateString()
        delivery: false,
        type: "收入",
        resource: "",
        desc: "", //备注
        money: "", //金额
        money2: "", //金额
      },
      rules: {
        money: [
          { required: true, trigger: "blur", message: "金额不能为空" },
          {
            pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: "请输入正确额格式,可保留两位小数",
          },
          { validator: validateMoney, trigger: "blur" },
        ],

        type: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个活动性质",
            trigger: "change",
          },
        ],
        desc: [{ required: true, message: "请填写杂费名称", trigger: "blur" }],
      },
    };
  },
  watch: {
    Visible(val) {
      this.dialogVisible = val;
    },
  },
  created() {
    this.dialogVisible = this.Visible;
  },
  methods: {
    close() {
      this.$emit("closepop");
      this.$refs["ruleForm"].resetFields();
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) { 
        } else { 
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style>
.text-align {
  text-align: center;
}
.active {
  background-color: #f5f5f5;
}
.height {
  height: 40px;
}
.status-point {
  color: #ccc;
  background-color: #e6a23c;
  margin-right: 5px;
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
</style>
