<template>
  <el-dialog
    title="导入教材信息"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="close"
    width="50%"
  >
    <div class="myClient-top-10">
        <div class="width-100 text-center ">
              <el-button  type="success">下载导入模板</el-button>
            </div>
      <div class="width-100 text-center margin-top">
        <el-upload
          class="upload-demo"
          drag
          action="https://jsonplaceholder.typicode.com/posts/"
          multiple
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>

      <div class="width-100 text-center">导入须知：</div>
      <div class="flex justify-content-center margin-top">
        <ul class="margin-auto">
          <li>1.导入的教材默认为正常状态；</li>
          <li>2.教材的进阶/售价不能为负数；</li>
          <li>3.表头中红色字体标示的列为必填项；</li>
          <li>4.请不要进行插入列操作；</li>
          <li>5.一次最多只能导入1000条数据</li>
        </ul>
      </div>
    </div>
    <div class="flex justify-content-center margin-top">
      <el-button class="myClient-left-30" @click="close">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    Visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
      groupNmae: "",
    };
  },
  watch: {
    Visible(val) {
      this.dialogVisible = val;
    },
  },
  created() {
    this.dialogVisible = this.Visible;
  },
  methods: {
    close() {
      this.$emit("closepop");
    },
  },
};
</script>

<style>
</style>
